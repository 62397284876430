// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-closing-folders-js": () => import("./../../../src/pages/work/closing-folders.js" /* webpackChunkName: "component---src-pages-work-closing-folders-js" */),
  "component---src-pages-work-closing-folders-marketing-js": () => import("./../../../src/pages/work/closing-folders-marketing.js" /* webpackChunkName: "component---src-pages-work-closing-folders-marketing-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-this-week-in-food-js": () => import("./../../../src/pages/work/this-week-in-food.js" /* webpackChunkName: "component---src-pages-work-this-week-in-food-js" */),
  "component---src-pages-work-wave-js": () => import("./../../../src/pages/work/wave.js" /* webpackChunkName: "component---src-pages-work-wave-js" */),
  "component---src-pages-writing-index-js": () => import("./../../../src/pages/writing/index.js" /* webpackChunkName: "component---src-pages-writing-index-js" */),
  "component---src-pages-writing-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/writing/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-writing-markdown-remark-frontmatter-slug-js" */)
}

